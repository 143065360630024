
import { ref } from '@vue/reactivity';
import Navigation from "./components/Navigation.vue";
import { onMounted } from '@vue/runtime-core';

export default {
  name: "App",
  components: { Navigation },
  setup() {
      let showSplash = ref(true);

    onMounted(() => {
      setTimeout(() => {
        showSplash.value = false;
      }, 2000);
    });

    return { showSplash };
  },
};
