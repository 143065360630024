
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { defineComponent, onBeforeMount, ref } from "vue";
import { AgGridVue } from "ag-grid-vue3";

import { HistogramEntry } from '../tools/Histogram';
import { Author, Work } from '../clients/crossref';

import reuseJson from '../assets/data/reuse.json';
import { ReuseFromJson } from '../backend/models/Reuse';

import RIndex from '../backend/RIndex';

type AuthorRow = {
  givenname: string,
  familyname: string,
  reusedvalue: number,
  reusingvalue: number
}

type PublicationRow = {
  name: String,
  doi: String,
  reusedvalue: number,
  reusingvalue: number
}

interface HistogramContainer<T> {
  reused : Array<HistogramEntry<T>>,
  reusing : Array<HistogramEntry<T>>
}

interface RowContainer {
  authors: Array<AuthorRow>,
  papers: Array<PublicationRow>
}

function getFrequencyOrElseZero<T>(entry: HistogramEntry<T> | undefined): number {
  if(entry === undefined){
    return 0
  } else {
    return entry.frequency
  }
}

function uniqueFilter(value: Author, index: number, self: Array<Author>) {
    return self.findIndex(function(curr: Author){return curr.given === value.given && curr.family == value.family}) === index;
}


export default defineComponent({
  name: "ReuseMetrics",
  components: { AgGridVue },
  setup() {
    const isLoading = ref(false);
    const rowData = ref({authors:[], papers:[]} as RowContainer)
    const reuseData = (reuseJson as Array<any>).map(ReuseFromJson);
    const indexer = new RIndex(reuseData);

    onBeforeMount(async () => {
        isLoading.value = true;
        
        let researchers: HistogramContainer<Author> = { 
          reused: (await indexer.computeAuthorsReused()),
          reusing: (await indexer.computeAuthorsReusing())
          };
    
        let papers: HistogramContainer<Work> = {
          reused: (await indexer.computeWorksReused()),
          reusing: (await indexer.computeWorksReusing())
          };
        

        let allAuthors: Array<Author> = researchers.reused.map(p => p.entry).concat(researchers.reusing.map(p => p.entry)).filter(uniqueFilter)

        let allWorks: Array<Work> = Array.from(new Set(papers.reused.map(p => p.entry).concat(papers.reusing.map(p => p.entry))))


        rowData.value.authors = allAuthors
          .map(author => {
            let givennameVal = author.given == undefined ? "" : author.given
            var familynameVal = author.family == undefined ? "" : author.family

            let reusedVal = getFrequencyOrElseZero(researchers.reused.find(e => { return e.entry.given == author.given && e.entry.family == author.family}))
            let reusingVal = getFrequencyOrElseZero(researchers.reusing.find(e => { return e.entry.given == author.given && e.entry.family == author.family }))

            return {givenname: givennameVal, familyname: familynameVal, reusedvalue: reusedVal, reusingvalue: reusingVal}
          })

        rowData.value.papers = allWorks
          .map(work => {
            let title = work.title[0]

            let reusedVal = getFrequencyOrElseZero(papers.reused.find(e => { return e.entry.dOI == work.dOI }))
            let reusingVal = getFrequencyOrElseZero(papers.reusing.find(e => { return e.entry.dOI == work.dOI }))

            return {name: title, doi: work.dOI, reusedvalue: reusedVal, reusingvalue: reusingVal}
          })

        isLoading.value = false;
    })

    let defaultColDef = {
        sortable: true,
        sortingOrder: ["asc", "desc"],
        filterParams: {
          buttons: ["reset"],
          debounceMs: 300
        },
        wrapText: true, 
        autoHeight: true,
      }
    
    const columns = {
      authors:[
        {
          field: "givenname",
          headerName: "Given Name",
          filter: "agTextColumnFilter",
          resizable: true,
          flex:2
        },
        {
          field: "familyname",
          headerName: "Family Name",
          filter: "agTextColumnFilter",
          resizable: true,
          flex:2/*,
          cellRenderer: function(params: any) {
            let author = params.data.familyname

            var name = author.family == undefined ? "" : author.family!

            if(author.oRCID){
              name = '<a class="text-sm text-blue-700 underline" href="' + author.oRCID + '">' + name + "</a>"
            }

            return name;
          }*/
        },
        {
          field: "reusedvalue",
          headerName: "R+",
          headerTooltip: "Times publications of this author have been reused.",
          filter: "agNumberColumnFilter",
          flex:1
        },
        {
          field: "reusingvalue",
          headerName: "R",
          headerTooltip: "Times this author has reused other publications.",
          filter: "agNumberColumnFilter",
          flex:1
        }
      ],
      papers:[
        {
          field: "name",
          headerName: "Publication",
          filter: "agTextColumnFilter",
          resizable: true,
          flex:5,
          cellStyle:  {"line-height": '2.0', "text-align": "left"},
          cellRenderer: ({data}: {data: PublicationRow}) => 
            data.doi ? (`<a href="https://doi.org/${data.doi}" target="_blank">${data.name}</a>`) : data.name
        },
        {
          field: "reusedvalue",
          headerName: "R+",
          headerTooltip: "Times this publication has been reused.",
          filter: "agNumberColumnFilter",
          flex:1
        },
        {
          field: "reusingvalue",
          headerName: "R",
          headerTooltip: "Times this publication has reused other publications.",
          filter: "agNumberColumnFilter",
          flex:1
        }
      ]
    }
    
    ;

    return { isLoading, columns, rowData, defaultColDef };
  },
});
