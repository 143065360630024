
import { defineComponent, onBeforeMount, ref, watch } from "vue";
import { WorkMessage } from "../clients/crossref";
import { CachedWorksApi } from "../tools/CachedWorksApi";

import router from "../tools/router";

import reuseJson from "../assets/data/reuse.json";
import { ReuseFromJson } from "../backend/models/Reuse";
import { useRoute } from "vue-router";

//import { Feed } from "../clients/arxiv";

interface ReuseLine {
  doi?: string;
  doiDetails?: any;
  alternativeId?: string;
  //arxivPreprint?: Feed,
  contributors: Array<string>;
}

export default defineComponent({
  name: "Paper",
  components: {  },
  setup() {
    const isLoading = ref(false);
    const paper = ref({} as WorkMessage);
    const reusedStuff = ref(new Array<ReuseLine>());
    const reusingStuff = ref(new Array<ReuseLine>());

    const doi = ref("");

    const reuseData = (reuseJson as Array<any>).map(ReuseFromJson);

    const worksApi = new CachedWorksApi();

    const route = useRoute();

    onBeforeMount(async () => {
        const doiPrefix = router.currentRoute.value.params.doiPrefix as string;
        const doiSuffix = router.currentRoute.value.params.doiSuffix as string;
        doi.value = `${doiPrefix}/${doiSuffix}`;
        await loadPaper();
    });

    watch(
      () => route.params,
      async newParams => {
        const doiPrefix = newParams.doiPrefix as string;
        const doiSuffix = newParams.doiSuffix as string;
        doi.value = `${doiPrefix}/${doiSuffix}`;
        await loadPaper();
    })


    async function loadPaper() {
      isLoading.value = true;

      paper.value = await worksApi.worksDoiGetInteral({
        doi: doi.value,
      }, true);

      const reducer = (
        accumulator: Array<ReuseLine>,
        currentValue: ReuseLine
      ) => {
        var target = accumulator.find((r) => currentValue.doi ? r.doi == currentValue.doi : r.alternativeId == currentValue.alternativeId );
        
        if (target === undefined) {
          accumulator.push(currentValue);
        } else {
          currentValue.contributors.forEach((c) =>
            target!.contributors.push(c)
          );
        }
        return accumulator;
      };

      reusedStuff.value = reuseData
        .filter((r) => r.reusedDOI.toLowerCase() == doi.value.toLowerCase())
        .map((r) => {
          return {
            doi: r.sourceDOI,
            contributors: new Array(r.contributor),
          };
        })
        .reduce(reducer, new Array<ReuseLine>());

      reusedStuff.value = await resolveDois(reusedStuff.value);

      reusingStuff.value = reuseData
        .filter((r) => r.sourceDOI.toLowerCase() == doi.value.toLowerCase())
        .map((r) => {
          return {
            doi: r.reusedDOI,
            alternativeId: r.alternativeID,
            contributors: new Array(r.contributor),
          };
        })
        .reduce(reducer, new Array<ReuseLine>());

        reusingStuff.value = await resolveDois(reusingStuff.value);

      isLoading.value = false;
    }

    async function resolveDois(
      stuff: Array<ReuseLine>
    ): Promise<Array<ReuseLine>> {
      return Promise.all(stuff.map(resolveDoi));
    }

    async function resolveDoi(reuse: ReuseLine): Promise<ReuseLine> {
      if (reuse.doi) {
        const workResult = await worksApi.worksDoiGetInteral({ doi: reuse.doi }, true).catch((err) => { console.warn(err); })
        if (workResult as WorkMessage) {
            reuse.doiDetails = (workResult as WorkMessage).message;
        }
      }
      return reuse;
    }

    return { paper, reusedStuff, reusingStuff, isLoading, reuseData, doi };
  },
});
