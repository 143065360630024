
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const open = ref(true);

    return { open };
  },
});
