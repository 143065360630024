
import { ref, onBeforeMount, PropType } from "vue";

import Reuse from '../backend/models/Reuse';

import RIndex from '../backend/RIndex';
import { HistogramEntry } from '../tools/Histogram';
import { Author, Work } from '../clients/crossref';

interface HistogramContainer<T> {
  reused : Array<HistogramEntry<T>>,
  reusing : Array<HistogramEntry<T>>
}

export default {
  props: {
    reuseData: Array as PropType<Array<Reuse>>,
  },
  setup(props : any) {
    const isLoading = ref(false);
    const researchers = ref({} as HistogramContainer<Author>);
    const publications = ref({} as HistogramContainer<Work>);
    const openResearchers = ref(true);
    const openArtifacts = ref(true);
    
    onBeforeMount(async () => {
      isLoading.value = true;
      const indexer = new RIndex(props.reuseData);
      
      researchers.value = { 
        reused: (await indexer.computeAuthorsReused()).slice(0,5),
        reusing: (await indexer.computeAuthorsReusing()).slice(0,5)
        };

      publications.value = { 
        reused: (await indexer.computeWorksReused()).slice(0,5),
        reusing: (await indexer.computeWorksReusing()).slice(0,5)
        };

      isLoading.value = false;
    });

    return { isLoading, researchers, publications, openResearchers, openArtifacts };
  }
}
