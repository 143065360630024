
import GraphView from "../components/GraphView.vue";
import TopFive from "../components/TopFive.vue";
import Stats from "../components/Stats.vue";
import Legend from "../components/Legend.vue";
import EditButton from "../components/EditButton.vue";
import EditDialog from "../components/EditDialog.vue"

import reuseJson from "../assets/data/reuse.json";
import { ReuseFromJson, ReuseType } from "../backend/models/Reuse";

import { $enum } from "ts-enum-util";
import { ref } from "@vue/reactivity";
import { provide } from '@vue/runtime-core';

export default {
  name: "Home",
  components: { Stats, TopFive, GraphView, Legend, EditButton, EditDialog },
  setup() {
    const selectedFilter = ref("ALL");

    const reuseData = (reuseJson as Array<any>).map(ReuseFromJson);

    const editDialogVisible = ref(false);

    const hideDialog = () => editDialogVisible.value = false;

    provide("hideDialog", hideDialog);

    const reuseTypes = $enum(ReuseType)
      .getEntries()
      .sort((a, b) => {
        if (a[1] < b[1]) return -1;
        else if (a[1] > b[1]) return 1;
        else return 0;
      });

    return { reuseData, reuseTypes, selectedFilter, editDialogVisible };
  },
};
